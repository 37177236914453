<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-5">
								<p>Follow this guide in order to upload subtitles to our system.</p>
								<p>
									This only works with Kodi, but once uploaded via Kodi subtitles will be available on any platform that supports Subtitles, including Startup
									Show, Tivimate and many more apps.
								</p>
								<div class="space-y-5">
									<p>Few things to know first;</p>
									<ul class="list-disc ml-10 space-y-3">
										<li>
											You can use our Subtitle Plugin only if you’re an active user of {{ $store.state.brandInfo.displayName }}, the Subtitle Plugin will only
											work with our addon
										</li>
										<li>
											Subtitles language will be based on your Subtitle settings in Kodi: ‘Kodi > Settings > Player Settings > Language > Download Services >
											“Language to Download Subtitles for”‘ – You can choose multiple Languages!
										</li>
										<li>
											If the subtitle isn’t fit in terms of timing, and you set offset in your subtitle, DO NOT UPLOAD that subtitle. Since our system can’t
											use that offset system platform-wise!
										</li>
										<li>If you abuse our system in a bad way, our Admin has every right to terminate your account without a refund!</li>
										<li>By being upload you accept these terms.</li>
									</ul>
								</div>
								<div class="space-y-3">
									<p>First, we’ll install the Subtitle Plugin.</p>
									<p>Go to add-ons, then click on the box</p>
									<ul class="ml-10 space-y-3">
										<li>
											1. Go to “Install from repository” > then {{ $store.state.brandInfo.displayName }} Repo > Subtitles > and install
											{{ $store.state.brandInfo.displayName }} Subs”.
										</li>
										<li>2. Once it’s installed right click the {{ $store.state.brandInfo.displayName }} Subs” and go to Settings</li>
										<li>3. Enable “Upload Sync Subtitles”</li>
										<li>
											<p>4. Once you start watching, click on the Subtitles icon at the bottom, then click on Download.</p>
											<p>
												If the subtitle is set to ENABLED, then it means there’s already a subtitle for this movie for your current language. In that case,
												you’ll see the subtitle name {{ $store.state.brandInfo.displayName }}
												Sub”
											</p>
											<p>Otherwise, when you click on Download, you’ll see a list of all the subtitles.</p>
										</li>
										<li>
											<p>5. Wait a couple of seconds till our server pulls all the subtitles with high enough ranking from OpenSubtitles.</p>
											<p>Choose the subtitle you think will fit (my suggestion is to start from the top since its based on rating).</p>
										</li>
										<li>
											<p>6. Now best would be to skip to the middle part and watch for 30-60 seconds.</p>
											<p>Then a pop-up will come up, asking if the subtitle is synced with the current media.</p>
											<p>If the subtitle is right, and the timing is right too (very important about timing!) then you can go ahead and click on YES.</p>
										</li>
									</ul>
								</div>
								<div class="space-y-1">
									<p>
										And then it will ask you again to double verify, if you’re 100% sure, then click YES again, and then the subtitle will get into our system.
									</p>
									<p>And it will be available on every platform using that language.</p>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HowDoUploadSubtitles',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
